import { Game } from "./Game";

export class Creator {
    private game: Game;
    private elmCreator: HTMLDivElement;
    private elmInput: HTMLInputElement;

    constructor(game: Game) {
        this.game = game;
        this.elmCreator = document.getElementById('creator') as HTMLDivElement;
        this.elmInput = document.getElementById('creatorData') as HTMLInputElement;
    }

    public init() {

        this.elmCreator.classList.remove('hidden');
        this.elmCreator.addEventListener('mousedown', (e) => {
            e.stopImmediatePropagation();
            e.stopPropagation();
        });

        this.elmInput.innerHTML = this.game.config().levels[0];
        document.getElementById('btnShowCreator').addEventListener('click', this.openCreator.bind(this));
        document.getElementById('btnUpdateLevel').addEventListener('click', this.creatorUpdate.bind(this));
    };

    private openCreator($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        // Open the creator overlay
        this.elmCreator.classList.add('open');
    };

    private creatorUpdate($event: MouseEvent) {
        $event.preventDefault();
        this.game.setLevels([this.elmInput.value]);

        // Close the creator overlay
        this.elmCreator.classList.remove('open');
    };

}