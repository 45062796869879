/**
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices. Uses the Separating Axis Theorem
 *
 * @param a an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @param b an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @return true if there is any intersection between the 2 polygons, false otherwise
 */
export interface Point {
    x: number,
    y: number
}

export interface IBox {
    left: number;
    right: number;
    top: number;
    bottom: number;
    rotation?: number;
    parentBox?: IBox
}

export function doBoxesOverlap(box1: IBox, box2: IBox) {
  const pointsA = convertBoxToPolygon(box1);
  const pointsB = convertBoxToPolygon(box2);
  return doPolygonsIntersect(pointsA, pointsB);
}

export function convertBoxToPolygon(box: IBox): Array<Point> {
  const points: Array<Point> = [];
  points.push({ x: box.left, y: box.bottom });
  points.push({ x: box.left, y: box.top });
  points.push({ x: box.right, y: box.top });
  points.push({ x: box.right, y: box.bottom });
  if (box.rotation) {
    points.forEach(point => {
      rotatePoints(box, point);
    })
  }
  return points;
}

function rotatePoints(box: IBox, point: Point) {
  // cx, cy - center of square coordinates
  // x, y - coordinates of a corner point of the square
  // theta is the angle of rotation
  // translate point to origin

  // center of box
  const cx = box.left + ((box.right - box.left) / 2); // center of box
  const cy = box.top + ((box.bottom - box.top) / 2);
  const theta = box.rotation * (Math.PI / 180);

  const x = point.x;
  const y = point.y;

  const tempX = x - cx;
  const tempY = y - cy;

  // now apply rotation
  const rotatedX = (tempX * Math.cos(theta)) - (tempY * Math.sin(theta));
  const rotatedY = (tempX * Math.sin(theta)) + (tempY * Math.cos(theta));

  // translate back
  point.x = rotatedX + cx;
  point.y = rotatedY + cy;
}

export function doPolygonsIntersect(a: Array<Point>, b: Array<Point>) {
  const polygons = [a, b];
  let minA: number | null = null;
  let maxA: number | null = null;
  let projected: number | null = null;
  let i: number | null = null;
  let i1: number | null = null;
  let j: number | null = null;
  let minB: number | null = null;
  let maxB: number | null = null;

  for (i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates
    // the two shapes
    const polygon = polygons[i];
    for (i1 = 0; i1 < polygon.length; i1++) {
      // grab 2 vertices to create an edge
      const i2 = (i1 + 1) % polygon.length;
      const p1 = polygon[i1];
      const p2 = polygon[i2];

      // find the line perpendicular to this edge
      const normal = { x: p2.y - p1.y, y: p1.x - p2.x };

      minA = maxA = null;
      // for each vertex in the first shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      for (j = 0; j < a.length; j++) {
        projected = normal.x * a[j].x + normal.y * a[j].y;
        if (minA === null || projected < minA) {
          minA = projected;
        }
        if (maxA === null || projected > maxA) {
          maxA = projected;
        }
      }

      // for each vertex in the second shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minB = maxB = null;
      for (j = 0; j < b.length; j++) {
        projected = normal.x * b[j].x + normal.y * b[j].y;
        if (minB === null || projected < minB) {
          minB = projected;
        }
        if (maxB === null || projected > maxB) {
          maxB = projected;
        }
      }

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (maxA < minB || maxB < minA) {
        return false;
      }
    }
  }
  console.log('HIT: polygons intersect!');
  return true;
};
