import { Game } from './Game';

document.addEventListener('DOMContentLoaded', e => {
  // eslint-disable-next-line no-new
  new Game();
});
// Load game

// document.getElementById('btnReplay').addEventListener('click', (e) => {
//   game.replay();
// });
